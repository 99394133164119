<template>
 <div :style="getAppStyle()+'width: 100%; height: 100%; position: relative;'">
   
     <div class='transition' :style='getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='float: top; width: 100%; vertical-align: middle;'>
		        <div style='vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'>TRACKER 1.0 </div>
		         <!-- 
		        <div style='vertical-align: middle; text-align: center; font-size: 11pt; font-weight: bold;width: 100%;'>Operations Optimisation</div>
		        -->
		        <div style='vertical-align: middle; text-align: center; color: blue; background-color: #eef;font-size: 11pt; font-weight: bold;width: 100%;'>Delivery Monitor</div>
		        <!-- 
		        <div style='padding-top: 10pt; vertical-align: middle; text-align: center; font-size: 10pt; font-weight: bold;width: 100%;'>CONTROLS</div>
		        
		        -->
		        <br/>
		         <InputSearch v-model="searchTerm" topic='delivery items' @search="doSearch(true)" usage='dashboard'/>
		        <br/>
		        <span class='SVcontrolLabel SVFont'>{{tr("SalesUnit")}}</span><br/>
        		<GSelectSimple :showOpener="false" :nulls="false" :options="unitOptions" v-model="selectedUnit.id" @change="doReload()"/>
        		<span class='SVcontrolLabel SVFont'>User:</span><br/>
        		<GSelectSimple v-if="ready" :showOpener="false" :options="entityMap.Fromuser.data" v-model="showUserId" @clear="resetUser()" />
        		<span class='SVcontrolLabel SVFont'>Status:</span><br/>

        		<GSelectMSimple v-if="ready" :showOpener="false" :options="entityMap.status.data" v-model="statusArray" />
		        <br/>
		        <span class='SVcontrolLabel SVFont'>Type:</span><br/>
				
        		<GSelectMSimple v-if="ready" :showOpener="false" :options="entityMap.PlacementType.data" v-model="typeArray" />
		        <br/>
		        <br/>
		    	
			    <div class='SVFont' style='display: inline-flex; width: 40%;'>
			    <InputDatePickSTD  class='SVFont' :auto="false" @enter="doReload"
                            v-model="fromDate"/>  
                            </div>

                <div class='SVFont' style='display: inline-flex; width: 40%;'>
                <InputDatePickSTD class='SVFont' :auto="false" @enter="doReload"
                            v-model="untilDate"/>     
                 </div>           

				
		        
		    	<button :style='getAppStyle()+"height:28px; width: 28px;"' title='reload all data' class='button SVFont' @click="doReload"><mdicon size="16" name='reload'/></button>
		    	
		    	<br/><br/>
		    	<button :style="getAppStyle()"  :disabled="!allowed.includes('/workflowOperationsSalesInput')" class='button xbutton SVFont' @click="$router.replace('/workflowOperationsSalesInput')">Sales Input
		    	<span v-if='notifySalesInput.todo' title="ToDo's"  class='w3-badge w3-blue'>{{notifySalesInput.todo}}</span>
		    	<span v-if='notifySalesInput.issues' title="Issues"  class='w3-badge w3-red'>{{notifySalesInput.issues}}</span>
		    	</button>
		    	<button :style="getAppStyle()"  :disabled="!allowed.includes('/workflowOperationsTrafficAssign')" class='button xbutton SVFont' @click="$router.replace('/workflowOperationsTrafficAssign')">Completion Screen
		    	<span v-if='notifyTraffic.todo' title="ToDo's"  class='w3-badge w3-blue'>{{notifyTraffic.todo}}</span>
		    	<span v-if='notifyTraffic.issues' title="Issues"  class='w3-badge w3-red'>{{notifyTraffic.issues}}</span>
		    	</button>

		        <br/>

			    
			    <br/>
			    <span class='dontWrap smallText bold SVFont'>Font-Size:</span>
			    <div style='width: 75%;padding-left: 5pt;'>
				<vue-slider @change='val => fontChanged(val)' :marks=true min=8 max=16 v-model="fontSize"></vue-slider>	
				</div>
				<br/>
		    	<br/>
		    	<span class='SVcontrolLabel SVFont'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		    	<br/>
		    	
		    	
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div class='transition' style='float: left; width: 86%; '> 
	    <!-- HEADER -->
	    <div style='float: top; width: 100%; height: 0pt; '>
		
	  	</div>
	    <!-- HEADER -->
	    
	    <!-- TABLE -->
	    <div v-if="ready" style='width: calc(100%); height: calc(100vh - 110px);'  >
	    	
	    	
	    	<div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
			    <div style='float: top;'>
				    <div v-for="(col,idx) in selectedColumns" :key="'ColH_'+idx" class='floatLeft tableHeader' :style='getColumnStyle(col, idx)'
						@click='setOrderBy(col)' @contextmenu='showColumnSelect($event)'
						>
				       <div class='header higher' 
				            :style="'background-color: '+((col.from=='booked'||col.show.startsWith('PERF'))?getBGColor('#cec','#484'):getBGColor('#ccf','#44a'))+'; color:'+getBGColor('#000','#fff')">
				       		<div v-if="col.icon"><mdicon :width="16" name="paperclip" /><sub>{{getOrderBy(col)}}</sub></div>
				       		<div v-else class='ltd' :title="col.name"> {{col.name}}<sub>{{getOrderBy(col)}}</sub></div>
				       	</div>
				    </div>
				   
			    </div>  <!-- float: top -->   
			</div> <!-- HEADER --> 
	    	<div style='width: 100%; height: calc(80vH) !important; overflow-y: scroll;' >
			    	<div   style='float: top; width: 100%;height: 100%;' >

			    	    <div v-for="(line,idx) in limit(data, showUserId)" 
			    	         @contextmenu="ctxMenu($event, line, idx)"
			    	          class='reqLine' :key="'DAT_'+idx" :ref="'DAT_'+idx" :id="'DAT_'+idx"
			    	         @drop="dropFile($event, line, idx)"
			    	         @dragenter.prevent="hoverLine('DAT_'+idx)"
			    	         @dragleave.prevent="leaveLine('DAT_'+idx)"
			    	        
			    	         @dragover.prevent="hoverLine('DAT_'+idx)"
			    	         @mouseenter="hoverLine('DAT_'+idx)"
			    	         @mouseleave="leaveLine('DAT_'+idx)"
			    	        >
  
			    	         
			    	        <div v-for="(col,idx2) in selectedColumns" :key="'CS_'+idx+'_'+idx2" class=' floatLeft'  :style='getColumnStyle(col, idx2, line)'>
			    	           <div v-if="col.show === 'ACTION'" class='reqDetail dontWrap' >
			    	           	<button :style="getAppStyle()" v-if="editable(line)" type='button' style='font-size:7pt; ' class='button' @click='update(line,idx)'><mdicon :width="16" name="content-save" /></button>
			    	           	<button :style="getAppStyle()" v-else type='button' disabled="true" style='font-size:7pt; ' class='button'><mdicon :width="16" name="content-save" /></button>
								<button :style="getAppStyle()" type='button' v-tooltip='"Booking confirmation"' :disabled='!isAvailable(line)' style='font-size:7pt; ' @click='downloadConf( line.placementId)' class='button'><mdicon :width="16" name="download" /></button>
			    	           </div> 
			    	           <div v-else-if="(col.show.startsWith('PERF')) && isFiller(line)" class='reqDetail dontWrap' style='font-size: 7pt; padding-left: 9pt; padding-top: 2pt;'>
			    	           <b>FILLER</b>
							   </div>
							   <div v-else-if="(col.show.startsWith('PERF')) && isBudget(line)" class='reqDetail dontWrap' style='padding-left: 2pt; padding-top: 4pt;'>
			    	           <GProgress :percentage="100"/>
							   </div>
							   <div v-else-if="col.show === 'PERFB'" @click='openTrafficManager(line, idx, col)' class='reqDetail dontWrap' style='padding-left: 2pt; padding-top: 4pt;'>
			    	           <GProgress :treshold="80" :percentage="getPercentB(line)"/>
							   </div>
							   <div v-else-if="col.show === 'PERFT'" @click='openTrafficManager(line, idx, col)' class='reqDetail dontWrap' style='padding-left: 2pt; padding-top: 4pt;'>
			    	           <GProgress :treshold="80" :percentage="getPercentT(line)"/>
							   </div>
							   <div v-else-if="col.show === 'PERFTPB'" @click='openTrafficManager(line, idx, col)' class='reqDetail dontWrap' style='padding-left: 2pt; padding-top: 4pt;'>
   			    	           <GProgress :treshold="80" :percentage="getPercentTPB(line)"/>
   							   </div>
							   <div v-else-if="col.show === 'PERFC'" @click='openTrafficManager(line, idx, col)' class='reqDetail dontWrap' style='padding-left: 2pt; padding-top: 4pt;'>
			    	           <GProgress :treshold="80" :percentage="getPercentC(line)"/>
							   </div>
						       <div v-else :class="'reqDetail dontWrap '+ col.class" :style='getColumnDetailStyle(col, line)'  
						            @click='openTrafficManager(line, idx, col)' >
						            <div v-if="(col.show === 'spots' || col.show === 'noOfSpots') && isBudget(line)">
						            <b>$$</b>
						            </div>
						            
						            <div v-else-if="(col.show === 'net') && isBudget(line)">
						            <b>{{formatNumber( line.net )}}</b>
						            </div>
						            <div v-else-if='col.show === "attachments"' @click='showAttachments(line)' class='attachment'>
						            	{{line.display[col.show]}}
						            </div>
						            <div v-else-if="col.from==='booked'" >
						                {{ print(line,col)}}
						            </div>
						            <div v-else-if="line.display[col.id]==='∞'" title="infinite (no limitation)">
						       			<mdicon size="14" name="infinity"/>
						       		</div>
									<div v-else :title="line.display[col.id]">
						       			{{line.display[col.id]}}
						       		</div>
						       	</div>
						     </div> 

				    	        
			    	    </div>
			    		
			    	</div> 
		    	</div>  <!-- TABLE -->


    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
    
   <GFWEOpenEditor ref='editor' @update='doReload'></GFWEOpenEditor>
   
   <ContextMenu v-if="ready" ref="menu">
      <template v-if="contextData && contextData.data"  slot-scope="{ contextData }">
        
		<ContextMenuItem v-if="!user.organisation" @clicked="$refs.menu.close(); sendBack(contextData.data, contextData.row);">
        	send back to sales
        </ContextMenuItem>
        
     
        <ContextMenuItem v-if="!user.organisation" @clicked="$refs.menu.close(); sendBackIssue(contextData.data, contextData.row);">
        	send back to sales (with issues)
        </ContextMenuItem>
        <ContextMenuItem v-if="!user.organisation" @clicked="$refs.menu.close(); setStatus(contextData.data, 6, contextData.row);">
        	set status to 'in delivery'
        </ContextMenuItem>
        <ContextMenuItem v-if='!user.organisation && contextData.data.placementId' @clicked="$refs.menu.close(); setStatus(contextData.data, 8, contextData.row); ">
        	set to 'traffic done'
        </ContextMenuItem>
        <ContextMenuItem v-if="!user.organisation"  @clicked="$refs.menu.close(); reset(contextData.data, contextData.row);">
        	reset
        </ContextMenuItem>
        <ContextMenuSep v-if="!user.organisation" />
        <ContextMenuItem @clicked="$refs.menu.close(); openCompleter(contextData.data, contextData.row, 'copy');">
        	Copy assistant (same campaign)
        </ContextMenuItem>
        <ContextMenuItem @clicked="$refs.menu.close(); openCompleter(contextData.data, contextData.row, 'copyTo');">
        	Copy assistant (different campaign/product)
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if="contextData.data.placementId && !contextData.data.locked" @clicked="$refs.menu.close(); openTrafficManager(contextData.data, contextData.row)">
        	Open Traffic Manager {{contextData.data.name}}
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if="!user.organisation"  @clicked="$refs.menu.close(); $refs.editor.open('SalesRequest', contextData.data.id); ">
        	Edit Request {{contextData.data.name}} 
        </ContextMenuItem>
        <ContextMenuItem v-if="!user.organisation"  @clicked="$refs.menu.close(); adjust('SalesRequest', contextData.data.id); ">
        	Adjust request {{contextData.data.name}} 
        </ContextMenuItem>
        <ContextMenuItem v-if="!user.organisation"  @clicked="$refs.menu.close(); adjust('SalesRequest'); ">
        	Adjust all displayed requests  
        </ContextMenuItem>
        <ContextMenuItem :disabled="contextData.data.locked" v-if="!user.organisation && contextData.data.placementId"  @clicked="$refs.menu.close(); $refs.editor.open('Placement', contextData.data.placementId); ">
        	Edit {{tr('Placement')}}
        </ContextMenuItem>
		<ContextMenuItem :disabled="contextData.data.locked" v-if="!user.organisation && contextData.data.placementId"  @clicked="$refs.menu.close(); deletePlacement( contextData); ">
        	Delete {{tr('Placement')}}
        </ContextMenuItem>
        <ContextMenuItem :disabled="contextData.data.locked" v-if="!user.organisation && contextData.data.placementId"  @clicked="$refs.menu.close(); adjust('Placement', contextData.data.id); ">
        	Adjust {{tr('Placement')}}
        </ContextMenuItem>
        <ContextMenuItem :disabled="contextData.data.locked" v-if="!user.organisation && contextData.data.placementId"  @clicked="$refs.menu.close(); adjust('Placement'); ">
        	Adjust all {{tr('Placement')}}s
        </ContextMenuItem>
        
        <ContextMenuItem :disabled="contextData.data.locked"  @clicked="$refs.menu.close(); downloadConf( contextData.data.placementId); ">
        	Print confirmation
        </ContextMenuItem>
        <ContextMenuSep/>
       <ContextMenuItem  @clicked="$refs.menu.close(); resetColumns( selectedDefault); ">
        	Reset columns (Default 1)
       </ContextMenuItem>
       <ContextMenuItem  @clicked="$refs.menu.close(); resetColumns( selectedDefault2); ">
        	Reset columns (Default 2)
       </ContextMenuItem>
       <ContextMenuItem  @clicked="$refs.menu.close(); resetColumnsUser(); ">
        	Reset columns to last saved 
       </ContextMenuItem>
       <ContextMenuSep/>
        <ContextMenuItem  @clicked="$refs.menu.close(); calcWidths(true); ">
        	Save columns
       </ContextMenuItem> 



       </template>
    </ContextMenu>

    <PlacementWizard ref='placementWizard' @placement='doReload' @openInvEditor="openInvEditor"></PlacementWizard>
	
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
	<PDFViewer name='pdfView' ref='pdfView' />
	
	<GConfirm ref='confirm'/>
	
	<ColumnSelector v-show='showColumnSelector' 
	        :availableColumns=availableColumns
	        :selected=selected
	 		:byId="true"
			@selectedColumns=setSelectedColumns
	        @close="showColumnSelector=false">
        
    </ColumnSelector>
    
 </div>
</template>

<script>
import {HTTP, HTTPMP, opsAPI, fwAPI, userAPI, invAPI, reportAPI, setReload, myLocale, formatNumber, getSalesUnit, showError} from '@/variables.js';
import {getDataByName}  from '@/utils.js';
import { store, restore} from '@/OperationScreen.js';
import { tr } from '@/translate.js';
import { formatPercent, format } from '@/Numbers.js';
import { getAppStyle, isDarkMode, initAppMode, getFG, getBG, getBGColor } from '@/AppStyle.js';
import { setGoBack } from '@/breadCrumb.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import PlacementWizard from '@/components/Ops/PlacementWizard';
import PDFViewer from '@/components/PDFViewer';
import InputTimezone from '@/components/inputElements/InputTimezone';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import InputDatePickSTD from '@/components/inputElements/InputDatePick3';
import InputText from '@/components/inputElements/InputText';
import InputMoney from '@/components/inputElements/InputMoney';
import InputInt from '@/components/inputElements/InputInt';
import GProgress from '@/components/misc/GProgress';
import ProgressBar from '@/components/ProgressBar';
import InputSearch from '@/components/inputElements/InputSearch';
import ColumnSelector from '@/components/ColumnSelector';

import GConfirm from '@/components/GConfirm';
import GTableJSON from '@/components/GTableJSON';
import clipboard from '@/components/Clipboard';
import ClickOutside from 'vue-click-outside'
import GSelectSimple from '@/components/GSelectSimple';
import GSelectMSimple from '@/components/GSelectMSimple';
import JQuery from "jquery";
import 'w3-css/w3.css';
let $ = JQuery;
var timers = [];

export default {
  name: 'GFW_TASKER',
  components : {
   PDFViewer, GProgress,InputSearch,ColumnSelector,ProgressBar,
   'app-switch': Switch, PlacementWizard, ContextMenu, ContextMenuItem, ContextMenuSep, InputDatePickSTD, GSelectSimple, GSelectMSimple, GConfirm
  },
  data () {
    return {
      stationId: 0,
      station: {},
      searchTerm: "",
      search: "",
      selectedStation: {},
      selectedRequestId: null,
      currency: {},
      stations: [],
      name: "",
      fromDate: {},
      untilDate: {},
      loadingActive: false,
      controlsRight: false,
      statusArray: [5,6,8],
      typeArray: [],
      statusArrayAllowed: [1,2,3,4,5,6,8,9,10,11,12],
      unitOptions: [],
      selectedUnit: {},
      showUserId: 0,
      
      user: null,
      
      data: [],
      metadata: {},
      entityMap: [],
      editRow: -1,
      allowed: [],
      jumpToNext: false,
      editCell: -1,
      editField: {},
      rowChanged: [],
      valueBefore: {},
      
      booked: [],
      
      ready: false,
      showProgressBar: false,
      pbTitle: "",
      pbAction: "",
      pbSalesRequestId: null,
  	  pbWhat: "",
      
      dropRow: -1,
      
      fileCount: 0,
	  isInitial: true,
	  isSaving: false,
      
      allDetailIDs: [],
      detailEntityMap: {},
      selectedDetail: {},
      metadataDetail: {},
      detailCounter: 0,
      showEditorDetail: false,
      
      selectorInput: null,
      formatNumber,
      newLine: null,
      
      showColumnSelector: false,
      notifyTraffic: {},
      notifySalesInput: {},
      fontSize: 11,
      widthCalculated: 100,
      orderBy: [],
	  tr, getAppStyle, getBGColor,
	  availableColumns: [
	        {id: 1, name: "Reference", show: "name", type: 'txt', disabled: true, class:'centered', w: 5},
	        {id: 2, name: tr("SalesUnit"), show: "salesUnitId", type: 'select', disabled: true, class:'centered', w: 3},
	        {id: 3, name: "Channel", show: "channelId", type: 'select', disabled: true, class:'left', w: 3},
	        {id: 4, name: "Received", show: "received", type: 'date', disabled: true, class:'centered', w: 3},
	        {id: 5, name: "From", show: "fromDate", type: 'date', disabled: true, class:'centered', w: 3},
	        {id: 6, name: "To", show: "untilDate", type: 'date', disabled: true, class:'centered', w: 3},
			{id: 44, name: "Year", show: "year", type: 'int', disabled: true, class:'centered', w: 3},
			{id: 46, name: "Lock", show: "lock", type: 'txt', disabled: true, class:'centered', w: 3},
			{id: 47, name: "Free%", show: "incentiveDiscount", type: 'mny', disabled: true, class:'centered', w: 2},
			{id: 48, name: tr("Placement"), show: "placementName", type: 'txt', disabled: true, class:'centered', w: 4},
						
	        {id: 7, name: "Advertiser", show: "advertiserName", type: 'txt', disabled: true, class:'left', w: 6},
			{id: 45, name: "Campaign", show: "campaignName", type: 'txt', disabled: true, class:'left', w: 6},
				        
			{id: 8, name: tr("Product"), show: "productName", type: 'txt', disabled: true, class:'left', w: 6},
	        {id: 9, name: "Type", show: "usedTypeId", type: 'txt', disabled: true, class:'centered', w: 4},
	        {id: 10, name: "TG", show: "targetgroup", type: 'txt', disabled: true, class:'centered', w: 2},
	        
 	        {id: 11, name: "Spots", show: "bookedSpots", type: 'int', class:'centered', from: 'booked', w: 2},
 	        {id: 55, name: "Bonus", show: "bookedFrees", type: 'int', class:'centered', from: 'booked', w: 2},
 	        {id: 12, name: "GRP", show: "bookedGRP", type: 'int', class:'centered', from: 'booked', w: 2},
 	        {id: 13, name: "CPP", show: "cpp", type: 'mny', class:'centered', from: 'booked', w: 2},
 	        {id: 14, name: "Invalid", show: "invalid", type: 'int', class:'centered', from: 'booked', w: 2},
 	        {id: 15, name: "Missed", show: "missed", type: 'int', class:'centered', from: 'booked', w: 2},
 	        {id: 16, name: "Preempted", show: "preempted", type: 'int', class:'centered', from: 'booked', w: 2},
	        {id: 17, name: "Booked", show: "bookedBudget", type: 'mny', class: 'centered', from: 'booked', w: 3},
	        
	        

	        {id: 18, name: "Spots", show: "noOfSpots", type: 'int', class:'centered',  w: 2},
	        
	        {id: 19, name: "GRP", show: "grp", type: 'int', class:'centered',  w: 2},
	        {id: 20, name: "Budget", show: "net", type: 'mny', class: 'centered', w: 3},
	        {id: 40, name: "Camp budget", show: "campaignBudget", type: 'mny', class: 'centered', w: 3},
	        {id: 41, name: "Camp Budget booked", show: "campaignBudgetBooked", type: 'mny', class: 'centered', w: 3},
	        
	        {id: 21, name: "Perf. Budget", show: "PERFB", w: 5},
	        {id: 35, name: "Perf. Target", show: "PERFT", w: 5},
			{id: 43, name: "Perf. Target incl Bonus", show: "PERFTPB", w: 5},
	        {id: 42, name: "Perf. Campaign", show: "PERFC", w: 5},
			
	        {id: 28, name: "Priority", show: "priority", type: 'txt', class:'centered', w: 2},
	        {id: 29, name: "Rotation", show: "rotation", type: 'txt', class:'centered', w: 4},
	        {id: 30, name: "PosRQ", show: "positionRequest", type: 'txt', class:'centered', w: 4},
	        {id: 31, name: "Layer", show: "layer", type: 'txt', class:'centered', w: 2},
	        {id: 32, name: "Pricing", show: "pricing", type: 'txt', class:'centered', w: 3},
	        {id: 33, name: "Status", show: "placementStatus", type: 'txt', class:'centered', w: 3},
	        {id: 34, name: "Safety", show: "safety", type: 'mny', class:'centered', w: 3},
	        {id: 36, name: "Dur", show: "duration", type: 'txt', class:'centered', w: 2},
	        {id: 37, name: "Capping", show: "capping", type: 'txt', class:'centered', w: 4},
	        {id: 38, name: "Filter", show: "filter", type: 'txt', class:'centered', w: 5},
	        {id: 39, name: "Pacing", show: "loading", type: 'txt', class:'centered', w: 2},
	        
	        {id: 22, name: "Traffic", show: "trafficUser", type: 'select', disabled: true, class:'centered', w: 4},
	        {id: 23, name: "OPT", show: "opt", type: 'txt', disabled: true, class:'centered', w: 2},
	        {id: 24, name: "Status", show: "status", type: 'select', disabled: true, class:'left', w: 4},
	        {id: 25, name: "Issues", show: "issues", type: 'select', disabled: true, class:'centered', w: 3},
			{id: 26, name: "attachment", show: "attachments", type: 'select', icon:true, disabled: true, w: 1},
	        {id: 27, name: "Action", show: "ACTION", w: 4}
        ],
        selectedIN: "",
        selectedDefault: [1,2,3,4,5,6,7,8,9,10,11,55,12,13,14,15,17,18,19,20,21,22,23,24,25,26,27],
        selectedDefault2: "1,3,5,6,7,8,9,10,18,11,55,19,12,13,20,17,21,35,22,23,24,25,26,27",
        selected: [],
        selectedColumns: [
	        
        ]
    }
  },    
  directives:{
    ClickOutside,
  },
  methods: {
    myLocale() { return myLocale();},
    filesChange(form,file) { alert(form+"/"+JSON.stringify(file[0]))},
    doDrop(e) { JSON.stringify(55) },
    editable(line) { return (line && line.id)  },
    getPercentB( line)
    {
    	let i1 = line.net;
    	let i2 = line.bookedBudget;
    	//alert( 100*i2/i1); 
    	if ( i1 === 0 )
    	{
    		return Math.round(100);
    	}
    	return Math.round(100*i2/i1);
    }, 
    action( what, myId, api)
    {
   		this[what](myId, api)
    },
	isAvailable( line )
	{
		if ( ! line.placementId )
		{
			return false;
		}
		if ( line.locked )
		{
			return false;
		}
		return true;
	},
    getPercentC( line)
    {
    	let i1 = line.campaignBudget;
    	let i2 = line.campaignBudgetBooked;
    	//alert( 100*i2/i1); 
    	if ( i1 === 0 )
    	{
    		return Math.round(100);
    	}
    	return Math.round(100*i2/i1);
    },
    getPercentTPB( line)
    {
    	let i1 = 1;
    	let i2 = 0;
    	if ( line.grp )
    	{
    		i1 = line.grp;
    		i2 = line.bookedGRP;
    	}
    	else if ( line.noOfSpots )
    	{
    		i1 = line.noOfSpots;
    		i2 = line.bookedSpots+line.bookedFrees;
    	}
		else 
    	{
    		i1 = line.net*(1+line.incentiveDiscount*.01);
    		i2 = line.bookedMediaBudget;
    	}
    	//alert( 100*i2/i1); 
    	if ( i1 === 0 )
    	{
    		return Math.round(100);
    	}
    	return Math.round(100*i2/i1);
    },
	getInfoTPB( line)
	    {
			let txt = "";
	    	let i1 = 1;
	    	let i2 = 0;
	    	if ( line.grp )
	    	{
				txt = "grp/"
	    		i1 = line.grp;
	    		i2 = line.bookedGRP;
	    	}
	    	else if ( line.noOfSpots )
	    	{
				txt = "noS/"
	    		i1 = line.noOfSpots;
	    		i2 = line.bookedSpots+line.bookedFrees;
	    	}
			else 
	    	{
				txt = "bgt/"+line.net+"/"+line.incentiveDiscount+"//";
	    		i1 = line.net*(1+line.incentiveDiscount*.01);
	    		i2 = line.bookedMediaBudget;
	    	}
			txt += i1 +"/"+i2; 
	    	return txt;
	    },
	getPercentT( line)
    {
    	let i1 = 1;
    	let i2 = 0;
    	if ( line.grp )
    	{
    		i1 = line.grp;
    		i2 = line.bookedGRP;
    	}
    	if ( line.noOfSpots )
    	{
    		i1 = line.noOfSpots;
    		i2 = line.bookedSpots;
    	}
    	//alert( 100*i2/i1); 
    	if ( i1 === 0 )
    	{
    		return Math.round(100);
    	}
    	return Math.round(100*i2/i1);
    },
    showColumnSelect(event)
    {
        event.preventDefault();
    	this.showColumnSelector=true;
    },
    setSelectedColumns( sc ) { this.selectedColumns = sc; this.calcWidths( false ) },
    ctxMenu(event, data, idx) 
      { 
         event.preventDefault();
         this.$refs.menu.open(event, {data: data, row: idx} )
      },
    openTrafficManager( line, row, col ) {
        if ( col && col.show === 'attachments' )
        {
        	return;
        }
        if ( this.isBudget( line))
        {
        	return;
        }
        
        setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	this.$router.replace('bookMan?toOpen=Placement&id='+ line.placementId);
    },  
    openCompleter( line, row, action ) {
    	this.$refs.placementWizard.open({ line: line, row: row, mode: action}, 0, 0);
    },  
    sendBack(line, rowIdx) {
    	line.status = 1;
    	this.update( line, rowIdx);
    },
    resetUser()
    {
    	this.showUserId = 0; // sessionStorage.userId;
    },
    doSearch( force) {
	  	if ( force || (this.searchTerm))
		{
	    	this.search = this.searchTerm ? this.searchTerm.toLowerCase(): "*";
			
	    	this.limit( this.data);
		}
		
	  },
    sendBackIssue(line, rowIdx) {
    	let that = this;
    	let issue = 'missing attachment'
    	this.$iosPrompt({
	      text: 'Enter issue',
	      placeholder: issue
	    }).then( r => {
	        line.status = 4;
	        line.commentTraffic = r;
	    	that.update( line, rowIdx);
	    })
    	
    },
    reset(line, rowIdx) {
    	let that = this;
    	let issue = 'missing attachment'
    	line.status = 2;
    	line.campaignId = null;
    	line.placementId = null;
    	line.advertiserId = null;
    	line.agencyId = null;
    	line.productId = null;
    	line.trafficUser = null;
	    that.update( line, rowIdx, false, true);
    	
    },
    setStatus(line, status, rowIdx) {
        if ( line.status !== status )
        {
	    	line.status = status;
	    	this.update( line, rowIdx);
	     }
    },
    hoverLine(line)
    {
    	if ( isDarkMode())
    	{
    		$('#'+line).children().addClass('reqLineBGDM');
    	}
    	else
    	{
    		$('#'+line).children().addClass('reqLineBG');
    	}
    },
    leaveLine(line)
    {
    	if ( isDarkMode())
    	{
    		$('#'+line).children().removeClass('reqLineBGDM');
    	}
    	else
    	{
    		$('#'+line).children().removeClass('reqLineBG');
    	}
    },
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 14%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 14%; height: calc(100vh - 120px); padding: 2ex;"
    },
    showAttachments(line)
    {
    	let attList=line.attachments;
		let buttonArr = new Array();
		let that = this;
		for ( let i in attList )
		{
		    let fname = this.entityMap.Files.data.find(p=>p.id==attList[i]).label;
		    if ( fname.toLowerCase().endsWith('pdf') )
		    {
		    	buttonArr.push( { text: 'View ' + fname, onClick: function(){
					            that.download(attList[i], 'view');
					          }});
		    }
		    buttonArr.push( { text: fname, onClick: function(){
					            that.download(attList[i], 'download');
					          }});
		}
        buttonArr.push( { text: 'Cancel', onClick: function(){
					            
					          }});
    	this.$iosAlertView({
	      title: 'Attached files for ',
	      text: line.name,
	      buttons: buttonArr
	    });
    },
    edit(r, c, fld, line) 
    { 
    	if ( this.editable( line))
    	{
	    	this.editRow = r; 
	    	this.editCell = c; 
	    	this.jumpToNext=false; 
	    	this.editField = fld; 
	    	if (fld) 
	    	{
	    		this.valueBefore = this.data[r][fld.show];
	    	}
	    }
    },
    setDropRow(line, rowIdx) 
    { 
    	if ( this.editable( line))
    	{
	    	this.dropRow = rowIdx;
	    }
    },
    closeEdit() {  this.rowChanged[this.editRow] = true; if (this.jumpToNext) { this.jumpToNextColumn() } else {this.edit(-1,-1);} },
    jumpToNextColumn()
    {
    	this.editCell++;
    	this.jumpToNext=false; 
    	this.$nextTick(function () {
        	this.$nextTick(function () {
        		this.$refs.ifldEdit[0].open();
         	});
         });
    	
    },
    cancelEdit() { this.data[this.editRow][this.editField.show] = this.valueBefore;  this.rowChanged[this.editRow] = false; this.edit(-1,-1); },
    findColumn(column) { 
       return this.metadata.columns.find(p => p.column === column); 
    },
    clearable() { if ( sessionStorage.salesUnitId) { return sessionStorage.salesUnitId==0; } return false;},
    getColumnStyle(col, idx, line)
    {
    	let wTotal = this.widthCalculated;
    	let inc = 0;
    	if ( this.editCell >= 0 ) 
    	{
    		wTotal += inc;
    	}
		if ( line && !this.isAvailable( line))
		{
			return "width: " + ((parseInt(col.w)+inc)/wTotal*100) +"%; background-color: "+getBG()+"; color: #aaa;";
		}
    	if ( this.editCell === idx )
    	{
    		return "width: " + ((parseInt(col.w)+inc)/wTotal*100) +"%; background-color: "+getBG()+"; color: "+getFG();
    	}
        return "width: " + (parseInt(col.w)/wTotal*100) +"%; background-color: "+getBG()+";color: "+getFG();
    },
    getColumnDetailStyle(col, line)
    {
    	if ( !this.editable( line) )
    	{
    		return "cursor: not-allowed !important;font-size:"+this.fontSize+"px;";
    	}
     	return "font-size:"+this.fontSize+"px;";
    },
    applySearch( data)
    {
    	let filtered = [];
    	for (let di in data)
    	{
    		let line = data[di];
			let match = !this.searchTerm || this.searchTerm==="*";
			let search = this.searchTerm ? this.searchTerm.toLowerCase(): "*";
    		line.display = [];
    		
    		for (let ci in this.selectedColumns)
    		{
    			let col = this.selectedColumns[ci];
    			let displayValue = this.print( line, col);
    			line.display[ col.id ] = displayValue;
    			if ( displayValue )
    			{
	    			let singleMatch = displayValue.toString().toLowerCase().includes(search);
	    			
	    			match = (match || displayValue.toString().toLowerCase().includes(search));
    			}
    		}
    		if ( match )
    		{
    			filtered.push( line);
    		}
    	}
    	return filtered;
    },
    limit( data)
    {
        //let tmp = [...data];
        let tmp = this.doFilter( data);
        tmp = this.applySearch( tmp);
    	let array = this.doOrderBy( tmp);
    	if ( this.newLine )
    	{
    		array.push( this.newLine);
    	}
    	
    	return array;
    },
    adjust( what, salesRequestId) 
    {
    	this.pbAction = "adjustInternal";
    	this.pbInfo = "Adjust "+ what;
    	this.pbSalesRequestId = salesRequestId;
    	this.pbWhat = what;
    	this.showProgressBar = true;
    },
    adjustInternal( myId, api )
	{
	    let that = this;
	    let ids = [ this.pbSalesRequestId];
	    let what = this.pbWhat;
	    if (! this.pbSalesRequestId)
	    {
	    	let list = this.limit( this.data);
	    	ids = list.map( p=>p.id);
	    }
		api.post( opsAPI+"/adjust/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+what+"/"+myId, ids)	
				    		 .then( response => 
				             {
				            	 let lines = response.data;
				            	 for ( let lineIdx in lines )
				            	 { 
				            		 let line = lines[ lineIdx];
				            		 let changedLine = this.data.find( p => p.id === line.id);
					             	 //alert( JSON.stringify(changedLine));
					             	 if ( changedLine )
					             	 {
					             		this.$set( changedLine, 'net', line.net);
					             		this.$set( changedLine, 'noOfSpots', line.noOfSpots);
					             		this.$set( changedLine, 'grp', line.grp);
					             		this.$set( changedLine, 'fromDate', line.fromDate);
					             		this.$set( changedLine, 'untilDate', line.untilDate);
					             	 }
				            	 }
				            	 
				            	 that.showProgressBar = false;
				            	 that.$toast.success( lines.length+ " "+ what+"(s) adjusted", 'Ok', { timeout: 900, position: "topRight" });
				             }).catch(e => {
				            	    that.showProgressBar = false;
				                    showError( that.$toast, "adjust("+what+")", e);
				                });   
	},
	deletePlacement( ctxData )
	{
		let that = this;
		let name = ctxData.data.placementName;
		if ( name.length > 30)
		{
			name = name.substring( 0, 30)+"...";
		}
		this.$refs.confirm.confirm( {text: 'Delete '+name, title: "Delete "+tr('Placement'), button1: "Cancel", button2: "Delete"}).then(x => {
			    HTTP.delete( opsAPI+"/deletePlacement/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+ctxData.data.id)	
					 .then( response => 
			         {
			        	 that.$toast.success( "Placement deleted", 'Ok', { timeout: 900, position: "topRight" });
						 that.doReload();
			         }).catch(e => {
			                showError( that.$toast, "deletePlacement", e);
			            });   
       }).catch((err) => { 
       that.leaveLine( ctxData.row); 
       });
		
	},
    checkTraffic()
	{
	    let that = this;
		HTTP.post( opsAPI+"/countSalesRequests/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/1/toTraffic")	
				    		 .then( response => 
				             {
				                that.$set( that, 'notifyTraffic', response.data);
				             }).catch(e => {
				           		    
				                    showError( that.$toast, "loading sales-requests", e);
				                });   
	},
	checkSalesInput()
	{
	    let that = this;
		HTTP.post( opsAPI+"/countSalesRequests/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/Issues")	
				    		 .then( response => 
				             {
				                that.$set( that, 'notifySalesInput', response.data);
				             }).catch(e => {
				           		    
				                    showError( that.$toast, "loading sales-requests", e);
				                });   
	},
    addCampaign( line, campaign )
    {
    	//alert("addCampaign triggered")
    	this.entityMap.Campaign.data.push({id: campaign.id, label: campaign.name });
    	//alert( JSON.stringify(this.entityMap.Campaign.data));
    	let changedLine = this.data.find( p => p.id === line.id);
    	//alert( JSON.stringify(changedLine));
    	if ( changedLine )
    	{
    		this.$set( changedLine, 'campaignId', campaign.id);
    	}
    	this.$forceUpdate();
    },
    print( line, col)
     {
		//if (col)
        //return col.show;
        let xLine = line;
        if ( col.show === 'cpp' )
     	{
        	if ( !xLine.bookedGRP)
        	{
        		return "-";
        	}
     		return (Math.round( 100 * (xLine.bookedBudget/xLine.bookedGRP)/1000)/100)+'k';
     	}
		if ( col.show === 'year' )
		{
			let val = xLine[ 'fromDate' ];
		    let arr = val.split('-');
			return arr[0];
		}
        let val = xLine[ col.show ];
        if ( col.show.startsWith("campaignBudget") )
     	{
     		return (Math.round( 100 * val/1000)/100)+'k';
     	}
		if ( col.show === 'productName' )
     	{
     		return val;
     	}
     	if ( ! val )
     	{
     		return "-";
     	}
		
     	if ( col.show === 'name' )
     	{
     		return val;
     	}
     	if ( col.show === 'duration' )
     	{
			return val;
     	}
     	if ( col.type === 'mny' )
     	{
			if ( col.show === 'net' && this.isFiller( line) && val >= 1000000)
			{
				return "∞"
			}
			return format(val);
     	}
     	if ( col.show === 'opt' )
     	{
     		return val;
     	}
     	if ( col.type === 'date' )
     	{
     		let arr = val.split('-');
     		return arr[2]+"."+arr[1];
     	}
     	if ( col.show === 'currencyId' )
     	{
     		return this.entityMap.Currency.data.find(p=>p.id==val).additionalColumn[0];
     	}
     	if ( col.show === 'salesUnitId' )
     	{
     		return this.entityMap.SalesUnit.data.find(p=>p.id==val).label;
     	}
     	if ( col.show === 'channelId' )
     	{
     		return this.entityMap.Media.data.find(p=>p.id==val).additionalColumn[0];
     	}
     	if ( col.show === 'typeId' )
     	{
     		return this.entityMap.PlacementType.data.find(p=>p.id==val).label;
     	}
     	if ( col.show === 'usedTypeId' )
     	{
     		return this.entityMap.PlacementType.data.find(p=>p.id==val).label;
     	}
     	if ( col.show === 'campaignId' )
     	{
     		return this.entityMap.Campaign.data.find(p=>p.id==val).label;
     	}
     	if ( col.show === 'fromUser' )
     	{
     		return this.entityMap.Fromuser.data.find(p=>p.id==val).label;
     	}
     	if ( col.show === 'trafficUser' )
     	{
     		return this.entityMap.Fromuser.data.find(p=>p.id==val).label;
     	}
     	
     	if ( col.show === 'status' )
     	{
     		return this.entityMap.status.data.find(p=>p.id==val).label;
     	}
     	if ( col.show === 'issues' )
     	{
     		return this.entityMap.issues.data.find(p=>p.id==val).label;
     	}
     	if ( col.show === 'attachments' )
     	{
     		return val?val.length:0;
     	}
     	if ( col.boolean )
     	{
     		if ( val )
     		{
     			return "X";
     		}
     		return "";
     	}
     	if ( isNaN( val) )
     	{
     		return val?val:"";
     	}
     	try
     	{
     		
     		return val ? formatNumber( val ) : val;
     	}
     	catch(e) {
     		//
     	}
     },
    isFiller( line)
    {
    	 return this.entityMap.PlacementType.data.find(p=>p.id==line.typeId).additionalColumn[ 1];
    },
    isBudget( line)
    {
    	 return this.entityMap.PlacementType.data.find(p=>p.id==line.typeId).additionalColumn[ 2];
    },
    getOrderBy( col )
     {
     	for ( var i in this.orderBy)
     	{
     		let iCol = this.orderBy[i];
     		if ( iCol.show === col.show )
     		{
     		    if ( iCol.sorting > 0 )
     		    {
     				return "A"+(parseInt(i)+1);
     			}
     			return "D"+(parseInt(i)+1);
     		}
     	}
     	return "";
     },
    setOrderBy( col )
     {
        var add = true;
     	for ( var i in this.orderBy)
     	{
     		let iCol = this.orderBy[i];
     		if ( iCol.show === col.show )
     		{
     		    if ( col.sorting === 1 )
     		    {
     		    	col.sorting = -1;
     		    }
     		    else
     		    {
     				this.orderBy.splice( i, 1);
     				col.sorting = 0;
     			}
     			add = false;
     			break;
     		}
     	}
     	if ( add )
     	{
     		this.orderBy.push( col );
     		col.sorting = 1;
     	}
     	//this.doOrderBy();
     	this.$forceUpdate();
     	
     },
    doFilter(data)
     {
      	let tmp = data;
      	
     	tmp =  tmp.filter(line => (!line.status) || this.statusArray.find(p=>p===line.status) );
     	tmp =  tmp.filter(line => (!line.typeId) || this.typeArray.find(p=>p===line.typeId) );
     	if ( this.showUserId )
     	{
     		tmp =  tmp.filter(p => ((!p.trafficUser) || (p.trafficUser==this.showUserId)) );
     	}
     	//this.checkMark();
     	return tmp;
     },
     doOrderBy(data)
     {
        let that = this;
        
     	data.sort(function(a,b) {
	     	for ( var i in that.orderBy)
	     	{
	     		let iCol = that.orderBy[i];
	     		let v1 = a[ iCol.show ];
	     		let v2 = b[ iCol.show ];
	     		
	     		if ( ! v2 || v1 > v2 )
	     		{
	     			return iCol.sorting;
	     		}
	     		if ( ! v1 || v1 < v2 )
	     		{
	     			return -iCol.sorting;
	     		}
	     	}
	     	return 0;
	     });
		return data;
     },
    dropFile($event, line, rowIdx, lineREF)
    {
      event.preventDefault();
      event.stopPropagation();
      if ( ! line.id )
      {
      	showError( this.$toast, "Line " + line.name+ " is not saved yet", "");
      	return
      }
      this.showDropzone = false;
      const files = event.dataTransfer.files;
      const itemArray = [...files];
      
      const item = itemArray.find((i) => i.type.startsWith('application/pdf') || i.type.startsWith('application/vnd.openxmlformats'));
      let that = this;
      this.$refs.confirm.confirm( {text: 'Attach ' + item.name + ' to ' + line.name, title: "Add attachment", button1: "Cancel", button2: "Add file"}).then(x => {
       	  const formData = new FormData();
       	  this.startLoader();
       	  that.leaveLine(lineREF);
	      formData.append('file', item);      
	      if (item) this.upload(formData, line, rowIdx, item.name);
       }).catch((err) => { 
       that.leaveLine(lineREF); 
       });
    },
    async upload(data, line, rowIdx, name) {

      let uploadFile = await this.uploadFile(data, line, rowIdx, name);
     
    },
    uploadFile(formData, line, rowIdx, name) 
    {
      //this.cancelTokenSource = axios.CancelToken.source();
      let that=this;
      HTTPMP.put( opsAPI+"/fileUpload4Tracker/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+line.id, formData)
	            .then( response => 
	            { 
	                line.attachments = response.data.attachments;
	                for ( let i in line.attachments )
					{
					    let chk = that.entityMap.Files.data.find(p=>p.id==line.attachments[i]);
					    if ( ! chk )
					    {
					    	that.entityMap.Files.data.push({id: line.attachments[i], label: name});
					    }
					}
	                this.entityMap.Files.data.push()
	                that.$toast.success("File '"+name+"' uploaded to '"+line.name+"'", 'Ok', { timeout: 1500, position: "topRight" });
	                that.$forceUpdate();
      }).catch((err) => (showError( that.$toast, "File '"+name+"' not uploaded", err)))

    },
    resetColumns( newSelectedDefault)
    {
    	this.selectedIN = newSelectedDefault;
        this.arrangeColumns();
    },
    resetColumns2( newSelectedDefault)
    {
    	this.selectedIN = this.selectedDefault2;
        this.arrangeColumns();
    },
    resetColumnsUser()
    {
    	this.selectedIN = this.user.columnsOPSD;
        this.arrangeColumns();
    },
	doReload() {
		this.pbAction = "doReloadInternal";
    	this.pbTitle = "Loading data... ";
    	this.showProgressBar = true;
	},
    doReloadInternal(myId, api) {
    	let that = this;
    	this.booked = [];
    	this.calcWidths();
    	that.ready = false;
    	//alert( "doReload> "+JSON.stringify(this.selectedUnit))
    	if ( this.selectedUnit && this.selectedUnit.id >= 0 )
    	{
    		if ( this.selectedRequestId )
    		{
    			api.post( opsAPI+"/getSalesRequest/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+that.selectedUnit.id+"/"+sessionStorage.userId+"/"+this.selectedRequestId+"/2/"+myId)	
	       		 .then( response => 
	                { 
				    
				    that.data = response.data.data; // that.doOrderBy( that.doFilter(response.data.data));
	                that.user = response.data.user;   
	                that.selectedIN = that.user.columnsOPSD;
	                that.fromDate = new Date( that.data[0].fromDate);
	    	        that.untilDate = new Date( that.data[0].untilDate);
	                that.arrangeColumns();
	                that.metadata = response.data.metadata;
	                that.entityMap = response.data.entity2DataMap;
	                that.allowed = response.data.allowed;
	                let usedTypes  = that.data.map(p=>p.typeId);
	                if (!that.typeArray || !that.typeArray.length)
	                {
	                	that.typeArray = [...new Set(usedTypes)]
	                }
					that.newLine = null;
					that.rowChanged = [];
					that.closeEdit();
					that.$forceUpdate();
					that.ready = true;
					that.showProgressBar = false;
					if ( that.statusArrayAllowed.length )
					{
						that.entityMap.status.data.forEach( p => p.visible = that.statusArrayAllowed.includes( p.id ));
					}
	                
	                       
	                }).catch(e => {
	                       showError( that.$toast, "loading sales-requests", e);
						   that.showProgressBar = false;
	                   });   
    		}
    		else
    		{
		    	let dateFrom = new Date(that.fromDate).toISOString().split('T')[0];
		    	let dateUntil = new Date(that.untilDate).toISOString().split('T')[0];
		    	api.post( opsAPI+"/getSalesRequests/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+that.selectedUnit.id+"/"+sessionStorage.userId+"/"+dateFrom+"/"+dateUntil+"/2/"+myId)	
	    		 .then( response => 
	             { 
	                that.data = response.data.data; // that.doOrderBy( that.doFilter(response.data.data));
	                that.user = response.data.user;   
	                that.selectedIN = that.user.columnsOPSD;
	                that.arrangeColumns();
	                that.metadata = response.data.metadata;
	                that.entityMap = response.data.entity2DataMap;
	                that.allowed = response.data.allowed;
	                let usedTypes  = that.data.map(p=>p.typeId);
	                if (!that.typeArray || !that.typeArray.length)
	                {
	                	that.typeArray = [...new Set(usedTypes)]
	                }
					that.newLine = null;
					that.rowChanged = [];
					that.closeEdit();
					that.$forceUpdate();
					that.ready = true;
					that.showProgressBar = false;
					if ( that.statusArrayAllowed.length )
					{
						that.entityMap.status.data.forEach( p => p.visible = that.statusArrayAllowed.includes( p.id ));
					}
	                    
	             }).catch(e => { 
	                    showError( that.$toast, "loading sales-requests", e);
						that.showProgressBar = false;
	                });   
    		}
            
	    }
    },
    deleteLine( line ) {
        let that = this;
		this.$refs.confirm.confirm( {text: "Delete line "+line.name, title: "Delete", button1: "Cancel", button2: "Delete"}).then(x => {
	       	  HTTP.post( opsAPI+"/deleteRequest/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+line.id)
		        .then( response => 
		        { 
		            that.data =  that.data.filter( l => l.id !== line.id  );
		            //that.$toast.success("File '"+name+"' uploaded to '"+line.name+"'", 'Ok', { position: "topRight" });
		            that.$forceUpdate();
		        }).catch(e => (showError( that.$toast, "Line '"+line.name+"' not deleted", e)))
	    });
    },
    init() {
        let that = this;
		this.startLoader();
		this.checkTraffic();
    	this.checkSalesInput();
		this.showUserId = null;
		if ( !that.unitOptions || ! that.unitOptions.length || !that.selectedUnit || !that.selectedUnit.id)
	    {
        	that.unitOptions=new Array();
        	getSalesUnit( sessionStorage.salesUnitId, sessionStorage.unitId, that.unitOptions, that.selectedUnit).then( opts => {
        		that.unitOptions = opts;
        		that.doReload();
        	})
        	
	    }
	    that.setOrderBy( {show: 'status'});
	    that.setOrderBy( {show: 'fromDate'});
	   	that.stopLoader();
  
    },
    download( fileId, doWhat )
    {
    	let that = this;
    	if ( doWhat == 'download')
        {
	    	HTTP.post( opsAPI+"/download/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+fileId)
	        .then( response => 
	        { 
                let downloadResponse = response.data;
           		that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
        	}).catch(e => (showError( that.$toast, "file download error", e)))
        }
        else
        {
        	
        	HTTP.post( opsAPI+"/download/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+fileId)
	        .then( response => 
	        { 
                let downloadResponse = response.data;
           		//that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
                that.$refs.pdfView.open(downloadResponse.file, downloadResponse.filename)
           		
        	}).catch(e => (showError( that.$toast, "file download error", e)))
        }

    },
    downloadConf( placementId )
    {
    	let that = this;
		HTTP.post( opsAPI+"/downloadConf/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+placementId+"/1")
	        .then( response => 
	        { 
               
               let downloadResponse = response.data;
           	   //that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
               that.$refs.pdfView.open(downloadResponse.file, downloadResponse.filename)
           		
        	}).catch(e => (showError( that.$toast, "file download error", e)))

    },
    forceFileDownload(base64, name) 
	{  
      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    },
    base64ToArrayBuffer(base64) {
	    var binary_string = atob(base64);
	    var len = binary_string.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binary_string.charCodeAt(i);
	    }
	    return bytes.buffer;
	},
	setStatusCompleted(line, rowIdx, showSuccess, dontTouch)
	{
		line.status = 4;
		this.update(line, rowIdx, showSuccess, dontTouch) ;
	},
    update(line, rowIdx, showSuccess, dontTouch) 
    { 
        let that = this;
    	
            
            let statusSave = line.status;
            if ( ! dontTouch )
            {
        		if ( line.status === 5)
        		{
        	 		line.status = 6;
        		}
        		line.trafficUser = sessionStorage.userId;
        	}

        	if ( line && line.id > 0 )
        	{
        	 line.issues = 0;
        	 //alert(fwAPI+"/updateData/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/SalesRequest/"+line.id);
             HTTP.put( opsAPI+"/updateData/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/SalesRequest/"+line.id, line)
                    .then( response => 
                    {
                         Object.assign( line, response.data.data);
                         
                         that.jumpToNext = false;
                         that.closeEdit();
                         if ( showSuccess )
                         {
                         	that.$toast.success(line.name+" saved", 'Ok', { timeout: 2000, position: "topRight" });
                         }
                         
                         that.$set( that.rowChanged, rowIdx, false);
                         that.$forceUpdate();
                        
                    }).catch(e => {
                        line.status = statusSave;
                        showError( that.$toast, "update salesRequest", e);
                    });
           } else
           { 
            // alert("ins");
           	HTTP.put( opsAPI+"/updateData/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/SalesRequest/0", line)
                    .then( response => 
                    {
                         line = response.data.data;
                         that.data.push ( line );
                         that.newLine = null;
                         that.jumpToNext = false;
                         that.rowChanged = [];
                         that.closeEdit();
                         
                    }).catch(e => {
                        line.status = statusSave;
                   	    showError( that.$toast, "insert salesRequest", e);
                    });
           }
            
        },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
    stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	},
	closeEditorDetail()
	    {
	        this.showEditorDetail = false;
	    },
	nodeToOpen( event, module, dataId)
	    {
	        
	    	
	    },
	calcWidths( withSaving)
	{
	    let wTot = 0;
		var columns = "";
		for ( var i in this.selectedColumns )
     	{
     		wTot += this.selectedColumns[ i].w;
     		if ( columns )
     		{
     			columns += "," + this.selectedColumns[ i].id;
     		}
     		else
     		{
     			columns = this.selectedColumns[ i].id;
     		}
     	}
		this.widthCalculated = wTot;
		if ( withSaving )
     	{
	     	let saveUser = 0;
	     	
	     	if ( this.user && ! this.user.columnsOPSD)
	     	{
	     		saveUser = 1;
	     		this.user.columnsOPSD=columns;
	     	}
	     	if ( this.user && this.user.columnsOPSD != columns)
	     	{
	     		saveUser = 1;
	     		this.user.columnsOPSD=columns;
	     	}
	     	//alert( saveUser);
	     	if ( saveUser ) 
	     	{
	     		this.userUpdated( this.user);
			}
	     	else
	     	{
	     		this.$toast.info("column settings not changed", 'Ok', { timeout: 2000, position: "topRight" });
	     	}
		}
	},
	userUpdated( user) { 
		let that = this;
		this.user = user  
		
		HTTP.put( userAPI+"/setUserSpotColumn/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, this.user)
        .then( response => 
        { 
				that.user = response.data;
				that.$toast.success("column settings stored", 'Ok', { timeout: 2000, position: "topRight" });
        }).catch(e => {
        	showError( that.$toast, "updateUserColumns()", e);

        });
},
	arrangeColumns()
	{
		this.availableColumns = this.availableColumns.sort((a,b)=>a.name.localeCompare(b.name));
	    this.selectedColumns = new Array();
	    //alert( JSON.stringify( this.selectedIN))
	    if ( this.selectedIN)
	    {
	    	try
	    	{
	    		this.selected = this.selectedIN.split(',');
	    		//alert( this.selected)
	        } catch (e)
	        {
	        	//alert(JSON.stringify(e))
	        	this.selected = [];
	        }
	    	
	    }
	    //alert( JSON.stringify( this.selected))
	    if ( ! this.selected || ! this.selected.length )
	    {
	    	this.selected = [];
	    	
	    	for ( let i in this.selectedDefault )
			{
				let col = this.availableColumns.find( p=> p.id===this.selectedDefault[i])
				this.selected.push( col.id);
			}
	    }
	    //console.log(JSON.stringify(this.selected))
		for ( let i in this.selected )
		{
			let id = parseInt(this.selected[i]);
			for ( var j in this.availableColumns )
			{
				let column = this.availableColumns[j];
				if ( column.id === id )
				{
					this.selectedColumns.push( column );
					break;
				}
			}
	    }
	    this.calcWidths( false);
	},
	storeSettings() 
	{
		try
		{
			let obj = restore();
			if ( !obj )
			{
				obj = {};
			}
			
			if ( typeof this.selectedUnit === 'object')
			{
				this.selectedUnit = this.unitOptions.find(p=>p.id==this.selectedUnit.id)
			}
			obj.from = this.fromDate;
			obj.to = this.untilDate;
			obj.unit = this.selectedUnit;
			obj.typeArray = this.typeArray;
			obj.statusArray = this.statusArray;
			obj.searchTerm = this.searchTerm;
			obj.search = this.search;
	    	store( obj);
		} catch(e) { showError( this.$toast, "storeSettings()", e);  }
	},
  },
  beforeDestroy() {
	  
	  this.storeSettings();
  },
  created() {
    const today = new Date();
    if ( this.$route.params && this.$route.params.id )
    {
    	this.selectedRequestId = parseInt( this.$route.params.id);
    	if ( this.$route.params.to && this.$route.params.from )
	    {
	    	this.fromDate = new Date( this.$route.params.from);
	    	this.untilDate = new Date( this.$route.params.until);
	    }
    }
    else
    {
	    if ( this.$route.params && this.$route.params.from )
	    {
	    	
	    	this.fromDate = new Date( this.$route.params.from);
	    	this.untilDate = new Date( this.$route.params.until);
	    }
	    else
	    {
	    	
	    	let restoreView = restore();
	      	if ( restoreView && restoreView.from )
	      	{
	      		
	      		this.untilDate = restoreView.to;
	      		this.selectedUnit = restoreView.unit;
	      		
	      		this.searchTerm = restoreView.searchTerm;
	      		this.search = restoreView.search;
	      		
	      		if ( restoreView.typeArray )
		      	{
	      			this.typeArray = restoreView.typeArray;
		      	}
	      		this.fromDate = restoreView.from;
	      	}
	      	else
	      	{
	      		this.selectedUnit = {};
	      		this.fromDate = new Date(today.getFullYear(), today.getMonth() + 0, 2).toISOString().split('T')[0];
		      	this.untilDate = new Date(today.getFullYear(), today.getMonth() + 4, 1).toISOString().split('T')[0];
	      	}
	    }
    }
    
  	this.arrangeColumns();
  	this.calcWidths();
  	this.init();
  	initAppMode();
  	
  	this.$nextTick(function () {
	  	// force recalc () (open&close)--> workaround as the first time the context menu opens at (0,0)
	   	try {
	   		this.$refs.menu.open( {}); 
	   		this.$refs.completer.open({ });
	   		
	   	} catch (e) {
	   	//
	   	}
	   	try {
	   		this.$refs.menu.close();
	   		this.$refs.completer.close();
	   	} catch (e) {
	   	//
	   	}
   	});
  },
  watch: {
	//selectedUnit: function() { this.doReload(); },
	//fromDate: function() { if (this.ready && !this.selectedRequestId) this.doReload(); }
  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
		    // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>
<style lang="scss" scoped>

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }

.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
   
    margin-right: 4pt;

}  

.myButton:hover {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border: 1pt outset green;
    background-color: #e8f0e8;
}  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  //background-color: #eef; 
 }
.tableHeader {
  background-color: #444;
  color: #000;
}
.floatLeft {
    display: inline-flex;
    float: left;
}
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 8pt;
}
.SVFont {
	font-size: 9pt;
}
.SVcontrolLabelAvail {
	padding-top: 9pt;
	font-weight: bold;
	font-size: 9pt;
	cursor: pointer;
	color: #aaa;
}
.btn {
	height: 2.2em !important;
	padding-left: 0.5em;
	padding-right: 0.5em;
}
.input80 {
	width: 90% !important;
	display: inline-block;
	padding: 0;
	margin:0;
}
.input20 {
	width: 10% !important;
	display: inline-block;
	text-align: right;
	vertical-align: top;
	padding: 0;
	margin:0;
}

.reqDetail { 
  display: flex;
  align-items: center;
  font-size: 8pt;  
  width: 100% !important; 
  height: 24pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  overflow: hidden;
  cursor:pointer;
  //background-color: #aaa; 
}
.centered {
  justify-content: center;
}
.reqLine {
  // color: #000;
 // background-color: #eee !important; 
}
.reqDetail:hover {
 // background-color: #e8f0e8 !important; 
}
.reqLine:hover {
 // background-color: #e8f0e8 !important; 
  color: #000;
  text-shadow: 4px 4px 4px rgba(0.1, 0.1, 0, 0.2);
}
.dontWrap {
  overflow-x: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.ltd {
  padding-left: 1pt;
  padding-right: 1pt;
  overflow-x: hidden;
  font-size: 9px;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.wrap {
  white-space: wrap; /* Don't forget this one */
}
.reqLineBG {
  background-color: #e8f0e8 !important; 
}
.reqLineBGDM {
  background-color: #888 !important; 
  color: #eef;
}
.attachment {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
    justify-content: center;
}
.xbutton {
  width: 85% !important;
  font-weight: bold;
}
</style>
